import styles from './Portfolio.module.css';
import siteOne from '../../assets/dostavka.png';
import ProjectCard from '../../common/ProjectCard';

function Portfolio() {

  return (
    <section id="projects" className={styles.container}>
      <h1 className="sectionTitle">Projects</h1>
      <div className={styles.projectsContainer}>
        <ProjectCard
          src={siteOne}
          link="https://fastfoodlove.ru/"
          h3="FastFoodLove"
          p="Сайт доставка еды"
        />
        <ProjectCard
         src={siteOne}
         link="https://fastfoodlove.ru/"
         h3="FastFoodLove"
         p="Сайт доставка еды"
        />
        <ProjectCard
          src={siteOne}
          link="https://fastfoodlove.ru/"
          h3="FastFoodLove"
          p="Сайт доставка еды"
        />
       
      </div>
    </section>
  );
}


export default Portfolio;
